import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({

  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#D0006E', // Rosa viver mais 
        //primary: '#FF1B49', // Vermelho 
        secondary: '#ff6326', // Laranja 
        accent: '#0b2545', // AZUL ESCURO
        corVerde: '#98ED00',//Verde NEON viver mais
        background: '#333333',
      },
    },
  },

});
