import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import hotel from './modules/hotel'
import api from '../api/api'
//import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    user,
    hotel
  },

  state: {
    cidade: null,
    cidades: [],
  },

  mutations: {

    SET_CIDADE (state, cidade) {
      state.cidade = cidade
    },

    SET_CIDADES (state, cidades) {
      state.cidades = cidades
    },

  },
  actions: {

    async buscarCidadeByIataCode ({ commit }, iata) {
      commit('SET_CIDADE', await api.getCidadeByIata(iata))
    },

    //buscarCidadesAction ({ commit }, strAutocomplete) {
    buscarCidadesAction ({commit},str) {
      return new Promise((resolve, reject) => {
        api.getCidadesByString(
          str,
          (res) => {
            commit('SET_CIDADES', res)
            resolve(res)
          }
        ), error => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      })
    },

  },
  getters: {

  },

})
