import axios from 'axios'
import qs from 'qs'

//import availMockup from '@/api/avail-mockup.json'
import staticDestinations from '@/api/static-destinations.json'

axios.defaults.baseURL = 'https://api.viajehole.app'
//axios.defaults.baseURL = 'https://api.viajehole.com.br'

// DEV
//let developerAccessCode = 'UCiIZy50yV7b7hw5E8b6NauJgdIoxJz8FfwZZ/HnSjarhIlCpBgDGUoNnXR6QUgjmC5tOo06d3vJ7w9mvgcILg9gPArnXQ4BVz7c8qS6tJzQGxsfpmyEShUGwhOhrwGMPMXu2QzUOxsEbIsvfss+mJ/Bm8Z3c60/AOmlUgmwI0M='

// PROD - 16/05/2022
//let dac = 'Fmbx19aqaCnBZu1nfvaYjGt6eszI/haRvctAPKUWucdYTFlZSmH7UwvS9qn1tSi2NhH3ukz4nWiOmlKqEAf+cfIGU2dL0zH0F6tl+DCHw8hfDybppFzzL+MjwPFy8loLMhtA2juUPBDHr6HoKPq/dQMQYBZtnu95Ukvf38+eZco='


export default {

  getDependentes(idUsuario, cb) {
    console.log('entrou api getDependentes')
    // let params = qs.stringify({
    //   usuarioReserva: usuario
    // })
    axios
      .get('https://api.viajehole.app/assinatura/getdependentes/?titular_id='+idUsuario)
      .then(response => {
        console.log('AXIOS response', response)
        //console.log('RESPONSE DATA ROOMSTAYS',response.data.RoomStays)
        cb(response.data)
      }).catch(e => {
        console.log('axios catch')
        this.errors.push(e)
      })
  },

  reservas(idUsuario, cb) {
    console.log('entrou api reservas')
    // let params = qs.stringify({
    //   usuarioReserva: usuario
    // })
    axios
      .get('https://api.viajehole.app/reservas/getbyuser/?usuarioReserva='+idUsuario)
      .then(response => {
        console.log('AXIOS response', response)
        //console.log('RESPONSE DATA ROOMSTAYS',response.data.RoomStays)
        cb(response.data)
      }).catch(e => {
        console.log('axios catch')
        this.errors.push(e)
      })
  },

  res(form, cb) {

    console.log('entrou api res')

    form.idReserva = 'EXT_SUP_TEST_12345'
    //form.usuarioReserva = 21

    // let params = qs.stringify({
    //   form: form
    // })
    let params = qs.stringify(form)

    console.log('form api params', params)

    axios
      .post('https://api.viajehole.app/reservas/cadastro/', params)
      .then(response => {
        console.log('AXIOS response', response)
        //console.log('RESPONSE DATA ROOMSTAYS',response.data.RoomStays)
        cb(response.data)
      }).catch(e => {
        console.log('axios catch')
        //this.errors.push(e)
        console.log(e)
      })

  },

  descriptiveInfo(hotelCode, echoToken, hotelSignature, cb){

    let params = qs.stringify({
      form: {
        echo_token: echoToken,
        hotel_code: hotelCode,
        hotel_signature: hotelSignature,
        tpa_provider: 'H4sIAAAAAAAEAFMyt1QCAOKHv9EEAAAA',
        tpa_signature: 'H4sIAAAAAAAEAI1XbU/jOBD+K1E+3UkUOaHlZb+VAktPUHqU3Q93WqFp4oBvk7hrJxx7aP/7zfglL22KkEBynhl7PG/PuG9hBUpkoMNPf7+FBRdSp3wJT1DwspIIfjsIr2XF8werNlMCysSoo+QBXmFebKSupEdkBbmBNSLskHWgFVcvIpHb8F1dKXDgE68I0o/z1erR6T92FWZQrAUeMTkcH0eRvYE/EJfmUPd9IfDKKByz4/bzgvekX0pB/sseePmjFi+QYwBQOY6jk8M4GpBtHdWRDJ664NXWXRDZp/neFfrirRNMAHYN9uF39wyb3qszeBa3mrqxv1Q8kff4j2WVQuq1t+GebdRoL5dxxbHumnMHjDX56MPvXvA9Zwd09pj4aDg+c0XV2ctJT/Ze2vubO9jwplmtKGI/ZzJFOPyyuggPwnspi3sgP3SixKYSskRZR+C0EbkF9b3eNO3FPLLkKkFTtWsz1tW8lSXvet9KtnYdhHdJUm/mKdo6Gk9idsyi0HbwjqYlnmmFPoafKlVzD13qDU8E+v9ced25nm54iSwFqgK8RQa55i7SWLZXUpUJT6VqwjiThdCaVMs6z4leUCHPgSKzlLlIBNdeRrdDfeuU91CUSyUSvk1B+wq5d8aOEmO/vlFq9UaWYi1ygT3Bvfk7DBgkdFUk6nMJKjUriiFrpe5zgfyNsZ3meTAvk7zW4oWH1G/tXe/WGvkVEtnxUGykzT7WgXiSAyf8AeZLUAvbZKx4jmmQJbRQk1x7LBWWKyrJU6Al+Wma386duyxbEVtZfSNwKfIk0SUzAwxUPTs+Jv0LqMhGzOJoFLFRfPLA2Cfz91fYTgWbL+MwWaULrXgBJTTRoJ7w61W92XTX0yf+Wcm6wVzY/6yx8GSTkqESR4sXHKcYXXFV1amQwW8Pl7PrxXw2vXlc3t+d31ze/o6aqf647i28igJ1p2mdm1EctVg7sSPfORjM7qDsg0OlSyN5Z1MHG9hzy1Ph6Lo53zfNgOgjJ+zbPnhjEuw6+a6HO+7t017UBVcY2muJFJRS/7CmbV947tvgK0eTIoFddSu3GTrna3cCkgl+NzlskDaDDeQ3Udm2YluLRO6yNEdY4AoSxyX+kYaF1Vk2jejferaMbcSuQLzCZeXGLqlslCykIaK30M4La2YhC969A3KNJ9Zf2CFlJlXh6IaOUdht6nvzkCRbSxRTlB1tz1MMuomgoewwncTHKayzUXQCbDTm49PR+iyKRpCuzxhL2SSLz0KylBqWO7Jt6ZvP+eabs7znP2quq8Yatft2txL28HPjx+KAxOxw37MctO4C/TG7v4ODUbCQVaB4VuMzaJ3z3f03snyyB+OMewGRGzVXSKVEb5q9Q7zsB2GHURzX4rhsasMwPyhIKqxcXWHgLYb1gImwwyjyp0zx1SZTO5FYS1ddmPa6SbUzSFK9T0LjYplD2a0tj9m55rDldZeqb/DGmHr+ihXFqC1kqbGTOmPJM2Lbh3HTtjYGtvCNP0sF/0n7HPC/hXqvAx1IFSTPUD5xHRS4N8iwLwI/ddgk8FMn0M+ALq55oOv1PzypgkoGEGSi5IHMAnyZBXF8dMCODsMBswu5epb/onF8HUQj89fMM5MaLGpfc+hyRdlz07+DtO86XLkXVSJV6pJ0S4FyzPcZY4Dp5k7/q9CiU1XrpdS6FvQ2ovq44P5+Tc9iKEygwfGSiaaFibp68M7cYkNz66PUaK/W984X/nL6ePla8VJT6nzZ5IL6H98fXyE3DHMWn4wzQEqZRGMYjVkMI4B1OhrDaXJ6GmdnGUzMO5V68UF+5yXVK7c/TgTy16vr0TKjjsmdfWLpVNPrk7vnZ/ve6vzWdg8S3YEMef4PhHmcaKQPAAA='
      }
    })

    //console.log('Carregando cidades...')
    axios
      //.post('/hotel/descriptiveInfo/', params)
      .post('https://api.viajehole.app//hotel/descriptiveinfo/', params)
      .then(response => {
        console.log('AXIOS response', response.data)
        //console.log('RESPONSE DATA ROOMSTAYS',response.data.RoomStays)
        cb(response.data.RoomStays)
      }).catch(e => {
        console.log('axios catch')
        this.errors.push(e)
      })

  },

  avail(form, cb){
  //avail(cb){
    //cb(availMockup)
    console.log('entrou api avail', form)
    // let params = qs.stringify({
    //   form: form
    // })
    // let form2 = {
    //   // city_code: 'BSB',
    //   // checkin: '2022-06-26',
    //   // checkout: '2022-06-28',
    //   // adultos: 1,
    //   // criancas: 0
    //   city_code: form.city_code,
    //   checkin: form.checkin,
    //   checkout: form.checkout,
    //   adultos: form.adultos,
    //   //developerAccessCode: dac
    //   //criancas: 0
    // }
    // var formData = new FormData()
    // formData.append('form', form)
    //formData.append('developerAccessCode', dac)
    // formData.append(developerAccessCode)
    let params = qs.stringify(form)
    console.log('params', params)

    axios
      .post(// HOMOLOG
        'https://api.viajehole.app/hotel/avail/',
        params
      )
      .then(response => {
        //console.log('RESPONSE DATA ROOMSTAYS',response)
        cb(response.data)
      })
      .catch(e => {
        console.log('axios catch avail', e)
        //this.errors.push(e)
      })

  },

  // getDependentes(c){
  //   c(dependentes)
  // },

  getCidadeByIata(iata){
    //console.log(staticDestinations.Destinations )
    //console.log(iata)
    return staticDestinations.Destinations.find(d => d.IataCode == iata)
  },

  getCidadesByString(str, c){
    console.log('str',str)
    c(staticDestinations.Destinations)

    //axios.get('http://157.230.191.247/api/hotels/destinations/autocomplete?query=Bra')
    // axios.get('http://157.230.191.247/api/hotels/destinations/autocomplete?query='+str)
    // .then(response => {
    //   //console.log('AXIOS response')
    //   console.log('Response getCidadesApiCidades', response.data)
    //   c(response.data)
    // })
    // .catch(e => {
    //   console.log('axios catch')
    //   this.errors.push(e)
    // })

      // axios.get('http://157.230.191.247/api/hotels/destinations')
      // axios.get('http://157.230.191.247/api/hotels/destinations/autocomplete?query=Bra')
      //   .then(response => {
      //     //console.log('AXIOS response')
      //     console.log(response)
      //     c(response)
      //   }
      // ).catch(e => {
      //   console.log('axios catch')
      //   this.errors.push(e)
      // })

  },

}