//import hotel from "@/api/user"
import api from '../../api/api'
import router from '../../router'
import axios from 'axios'

export default {

  namespaced: true,

  state: {
    userData: null,
    accessToken: null,
    loginError: null,
    echoToken: null,
    dependentes: [],
  },

  getters: {
    isLogged: state => !!state.user,
  },

  mutations: {

    initialiseStore(state) {
      if (localStorage.getItem('userData')) {
        state.userData = JSON.parse(localStorage.getItem('userData'))
      }
    },

    LOGIN_STOP (state, errorMessage) {
      //state.loggingIn = false
      console.log('LOGIN STOP')
      state.loginError = errorMessage
    },

    // SET_ECHO_TOKEN (state, echoToken) {
    //   state.echoToken = echoToken
    // },

    SET_DEPENDENTES (state, dependentes) {
      state.dependentes = dependentes
    },

    SET_USER_DATA (state, userData) {
      //axios.defaults.headers.commom = { 'Authorization': `Bearer ${ userData.token }` }
      state.userData = userData
      state.accessToken = userData.token.DataAfterEncrypt
      localStorage.setItem('userData', JSON.stringify(userData))
    },

    clearUserData () {
      localStorage.removeItem('userData')
      router.push('/login').catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error
        }
      })
    },

    // logout () {
    //   localStorage.removeItem('userData')
    //   router.push('/login').catch(error => {
    //     if (error.name != "NavigationDuplicated") {
    //       throw error
    //     }
    //   })
    // },

    // UPDATE_ACCESS_TOKEN (state, accessToken) {
    //   state.accessToken = accessToken
    // },

  },

  actions: {

    fetchAccessToken({ commit }) {
      //commit('UPDATE_ACCESS_TOKEN', localStorage.getItem('accessToken'));
      console.log('localStorage',localStorage)
      commit('SET_USER_DATA', localStorage.getItem('userData'))
    },

    doLogin ({ commit }, credenciais) {
      //commit('LOGIN_START');
      console.log('credenciais',credenciais)
      return new Promise((resolve, reject) => {
        axios
          .post('https://api.viajehole.app/login/autenticar/', credenciais)
          .then(response => {
            console.log('STORE RESPONSE AXIOS LOGIN',response.data)

            if(response.data.token) {
              //localStorage.setItem('accessToken',response.data.token)
              let userData = response.data
              //let token = response.data.token
              //commit('UPDATE_ACCESS_TOKEN', response.data.token) // SET_TOKEN
              //commit('LOGIN_SUCCESS', userData )
              commit('SET_USER_DATA', userData )
              commit('LOGIN_STOP', null)
              resolve(response.data)
            }
            else {
              commit('LOGIN_STOP', "Erro no login")
              //reject("ERRO")
            }

          })
          .catch(error => {
            console.log('ERROR LOGIN_STOP', error)
            commit('LOGIN_STOP', error.response.data.error)
            //commit('UPDATE_ACCESS_TOKEN', null)
            commit('SET_USER_DATA', null)
            //   this.loading = false
            //   if (err.response.status === 401) {
            //       this.alert = true
            //   }
            reject(error)
          })
      }) // FECHANDO PROMISE
    },

    //carregarUsuariosReservaAction ({ commit }, user) {
    carregarUsuariosReservaAction () {
      return new Promise((resolve, reject) => {
        console.log('store carregando usuarios reserva')
        //state.carregandoDependentes = true
        api.getUsuariosReserva(
          (usuarios) => {
            //commit('SET_DEPENDENTES', dependentes)
            console.log('store usuarios reserva action', usuarios)
            //state.carregandoDependentes = false
            resolve(usuarios)
          }
        ), error => { reject(error) }
      })
    },

    carregarDependentesAction ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        console.log('store carregando dependentes')
        //state.carregandoDependentes = true
        api.getDependentes(
          userId,
          (dependentes) => {
            commit('SET_DEPENDENTES', dependentes)
            console.log('store dependentes', dependentes)
            //state.carregandoDependentes = false
            resolve(dependentes)
          }
        ), error => { reject(error) }
      })
    },

    logout ({ commit }) {
      commit('clearUserData')
    },

    endSession ({ commit }) {
      commit('clearUserData')
    },

  }

}