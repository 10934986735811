//import hotel from "@/api/hotel"
import api from '../../api/api'
import assinatura from '../../api/assinatura'

export default {

  namespaced: true,

  state: {
    hotel: null,
    hoteis: null,
    formBuscaHoteis: null,
    carregandoHoteis: false,
    carregandoQuartos: false,
    carregandoReservas: false,
    usuarioeDependentes: [],
    reservas: [],
  },

  getters: {

    getHotelById: (state) => (hotelId) => {
      // console.log('CARRINHO',state.carrinho)
      return state.hoteis[hotelId]
    }

  },

  mutations: {
    SET_USUARIO_E_DEPENDENTES (state, usuarioeDependentes) {
      state.usuarioeDependentes = usuarioeDependentes
    },

    SET_RESERVAS (state, reservas) {
      state.reservas = reservas
    },

    SET_HOTEL (state, hotel) {
      state.hotel = hotel
    },

    SET_LISTA_HOTEIS (state, hoteis) {
      state.hoteis = hoteis
    },

    SET_FORM_BUSCA_HOTEIS (state, form) {
      state.formBuscaHoteis = form
    },
  },

  actions: {

    carregarUsuarioeDependentesAction ({ commit }, params) {
      return new Promise((resolve, reject) => {
        assinatura.getUsuarioeDependentes(
          params,
          (ret) => {
            console.log('USUÁRIO E DEPENDENTES ACTION',ret)
            commit('SET_USUARIO_E_DEPENDENTES', ret)
            resolve(ret)
          }
        ), error => { reject(error) }
      }) // FECHANDO PROMISE
    },

    setFormBuscaHoteis({ commit }, form) {
      console.log('form busca hotéis', form)
      commit('SET_FORM_BUSCA_HOTEIS', form)
    },

    carregarReservasAction (_,user) {
      return new Promise((resolve, reject) => {
        console.log('store carregarReservasAction')
        //state.carregandoReservas = true
        console.log('store usuario ID', user)

          //commit('SET_RESERVAS', 'ae')
        api.reservas(
          user,
          (reservas) => {
            //commit('SET_RESERVAS', reservas)
            console.log('store reservas', reservas)
            //state.carregandoReservas = false
            resolve(reservas)
          }
        ), error => { reject(error) }
      })
    },

    res(_, form) {
      return new Promise((resolve, reject) => {
        console.log('form res', form)
        api.res(
          form,
          (res) => {
            console.log("store res", res)
            resolve(res)
          }
        ), error => { reject(error) }
      }) // FIM PROMISE
    },

    setHotelAction ({ commit, state }, hotelCode) {
      let h = state.hoteis.find( h => h.BasicPropertyInfo.HotelCode == hotelCode )
      commit('SET_HOTEL', h)
      state.carregandoQuartos = false
    },

    carregarPolitica (_, hotelCode) {
      console.log('store carregarPolitica hotelcode', hotelCode)
    },

    filtrarHoteisAction({ commit, state },formFiltro){
    //filtrarHoteisAction({ state },formFiltro){
      console.log('FILTRO',formFiltro)
      let hoteisCopy = state.hoteis.filter(h => h.BasicPropertyInfo.HotelName.includes(formFiltro.nomeHotel) )
      //state.hoteis = null
      //let newHoteis = hoteisCopy.shift(1,3)
      commit('SET_LISTA_HOTEIS', hoteisCopy)
      console.log('NEW HOTEIS', hoteisCopy)
    },

    async carregarHoteisAction ({ dispatch, commit, state }, params) {
      //carregarHoteisAction ({commit}, form) {
      //carregarHoteisAction () {

        // params.city_code == iata
        await dispatch('buscarCidadeByIataCode', params.city_code, {root: true}) // aguarda "buscarCidadeByIataCode" para continuar

        return new Promise((resolve, reject) => {
          // INCLUI DADOS NO state formBuscaHoteis

          //params.cidade = api.getCidadesByString(params.city_code)

          //commit('SET_FORM_BUSCA_HOTEIS', params)
          //console.log('form store carregarHoteisAction', form)
          state.carregandoHoteis = true

          api.avail(
            params,
            (hoteis) => {
              console.log('RES STORE HOTEIS',hoteis)
              commit('SET_LISTA_HOTEIS', hoteis.RoomStays.filter(h => h != null))
              //commit('SET_LISTA_HOTEIS', hoteis)
              //commit('SET_ECHO_TOKEN', hoteis.EchoToken)
              state.carregandoHoteis = false
              resolve()
            }
          ), error => { reject(error) }
        }) // FECHANDO PROMISE
      },

    // carregarHotelAction ({ commit, state }, hotelCode, echoToken, hotelSignature) {
    //   return new Promise((resolve, reject) => {
    //     state.carregandoHotel = true

    //     api.descriptiveInfo(
    //       hotelCode,
    //       echoToken,
    //       hotelSignature,
    //       (hotel) => {
    //         commit('SET_HOTEL', hotel)
    //         state.carregandoQuartos = false
    //         resolve()
    //       }
    //     ), error => { reject(error); }
    //   }) // FECHANDO PROMISE
    // },

  }

}