<template>
  <v-app id="inspire" class="pl-0 ml-0 black">

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      absolute
      elevation="1"
      class="black"
    >
      <v-app-bar-nav-icon color="primary" @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title class="pt-0" @click="$router.push('/admin/home')">
         <v-img
          contain
          lazy-src="../assets/images/hole-logo-branca.png"
          max-height="85"
          max-width="85"
          class=""
          src="../assets/images/hole-logo-branca.png"
          style="cursor: pointer"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        medium
        text
        class="grey--text text--lighten-2 mr-1 font-weight-medium"
      >Usuários</v-btn>
      <v-btn to="/admin" icon style="margin-right: 10px; cursor: pointer;">
        <v-icon color="primary" class="">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- <hbt-menu /> -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.url"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="">{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="pl-0 ml-0">
      <v-container class="pt-12">
        <v-row class="pt-0 mt-0">
          <v-col cols="12" class="align-top px-5">
            <slot/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
  import store from '../store'
  // import HbtMenu from '../Layout/Menu'

  export default {
    name: 'Dashboard',
    props: {
      source: String,
    },
    components: {
      // HbtMenu
    },
    data: () => ({
      drawer: null,
      items: [
        // { icon: 'mdi-home', text: 'Home', url: '/home' },
        // { icon: 'mdi-office-building', text: 'Hotéis', url: '/home' },
        // { icon: 'mdi-account-check', text: 'Minhas Reservas', url: '/minhas-reservas' },
        // { icon: 'mdi-view-carousel-outline', text: 'Meu Plano', url: '/meu-plano' },
        { icon: 'mdi-account-group', text: 'Usuários', url: '/admin/usuarios' },
        // { icon: 'mdi-account-supervisor', text: 'Dependentes', url: '/dependentes' },
        // { icon: 'mdi-share', text: 'Indique', url: '/indique' },
        { icon: 'mdi-logout', text: 'Sair', url: '/admin' },
      ],
      mini: true,
    }),
    mounted () {

    },
    watch: { },
    methods: {
      logout: () => {
        store.dispatch('logout')
      },
      profile: () => {
        alert('Information about User')
      },
      updateBreadcrumb () {
        this.breadcrumbsList = this.$route.meta.breadcrumb
      }
    }
  }
</script>

<style scoped>
 .dark-green {
    background-color: rgba(51, 51, 51, 1) !important;
 }
 .v-toolbar__title, .v-list-item__title {
    text-align: left;
 }
 .v-navigation-drawer {
    top: 64px !important;
    min-width: 65px !important;
 }
 .content-hole {
    margin-top: -25px;
 }
 .logo-icon {
    position: absolute;
    top: 0;
    left: 0;
 }
 .color-main-navigation {
    background-color: rgba(208, 0, 110, 1) !important;
 }
 .color-main-navigation .v-list-item__action .theme--light.v-icon{
    color: #fff;
 }
 .hole-assign h1 {
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: #F2F2F2;
 }
</style>