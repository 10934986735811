<template>
  <v-app id="inspire" class="pl-0 ml-0">

    <!-- <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      absolute
      color="dark-green"
      dark
      src="@/assets/images/hotel.jpg"
    > -->
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      absolute
      elevation="1"
    >
      <v-app-bar-nav-icon color="primary" @click="drawer = true"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="pt-0" @click="$router.push('/home')">
         <v-img
          contain
          lazy-src="../assets/images/logo-hole.png"
          max-height="85"
          max-width="85"
          class=""
          src="../assets/images/logo-hole.png"
        ></v-img>
      </v-toolbar-title>

      <!-- If need put a search here is the place -->
      <v-spacer></v-spacer>
      <!-- <v-app-bar-nav-icon @click.stop="mini = !mini" class="" style=""></v-app-bar-nav-icon> -->
      <div style="margin-right: 10px; cursor: pointer;">
        <v-icon  color="primary" class="">mdi-help-circle-outline</v-icon>
      </div>
      <!-- If need put a new Icon actions here is the place -->
      <!-- <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          large
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar
            size="32px"
            item
            color=""
          >
            <v-icon color="">
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item to="assinatura">
              <v-list-item-title>
                <v-icon color="corVerde" class="">mdi-card-account-details-outline</v-icon> Assinatura
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="this.profile">
              <v-list-item-title>
                <v-icon color="corVerde" class="">mdi-account-settings</v-icon> Perfil
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="this.logout">
              <v-list-item-title><v-icon class="red--text">mdi-logout</v-icon> Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu> -->
    </v-app-bar>

    <!-- <hbt-menu /> -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <!-- <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>John Leider</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider> -->

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.url"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="">{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


    <v-main class="pl-0 ml-0">
      <v-container
        class="pt-0"
      >
        <v-row class="no-gutters pl-2 py-0 my-0">
          <v-col cols="12" class="pl-0 ml-0">
            <v-breadcrumbs
              :items="breadcrumbsList"
              divider=">"
              class="pl-0 pb-0"
            >
              <v-breadcrumbs-item
                slot="item"
                slot-scope="{ item }"
                exact
                :to="item.to">
                {{ item.text }}
              </v-breadcrumbs-item>
            </v-breadcrumbs>
          </v-col>
        </v-row>
        <v-row class="pt-0 mt-0">
          <!-- <v-col col="12" sm="12" class="content-hole"> -->
          <v-col cols="12" class="align-top px-5">
            <slot/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
  import store from '../store'
  // import HbtMenu from '../Layout/Menu'

  export default {
    name: 'Dashboard',
    props: {
      source: String,
    },
    components: {
      // HbtMenu
    },
    data: () => ({
      breadcrumbsList: [],
      drawer: null,
      items: [
        // { icon: 'mdi-home', text: 'Home', url: '/home' },
        { icon: 'mdi-office-building', text: 'Hotéis', url: '/home' },
        { icon: 'mdi-account-check', text: 'Minhas Reservas', url: '/minhas-reservas' },
        { icon: 'mdi-view-carousel-outline', text: 'Meu Plano', url: '/meu-plano' },
        { icon: 'mdi-account-circle', text: 'Meu Perfil', url: '/perfil' },
        { icon: 'mdi-account-supervisor', text: 'Dependentes', url: '/dependentes' },
        { icon: 'mdi-share', text: 'Indique', url: '/indique' },
        { icon: 'mdi-logout', text: 'Sair', url: '/' },
      ],
      mini: true,
    }),
    mounted () {
      this.updateBreadcrumb()
    },
    watch: { '$route' () { this.updateBreadcrumb() } },
    methods: {
      logout: () => {
        store.dispatch('logout')
      },
      profile: () => {
        alert('Information about User')
      },
      updateBreadcrumb () {
        this.breadcrumbsList = this.$route.meta.breadcrumb
      }
    }
  }
</script>

<style scoped>
 .dark-green {
    background-color: rgba(51, 51, 51, 1) !important;
 }
 .v-toolbar__title, .v-list-item__title {
    text-align: left;
 }
 .v-navigation-drawer {
    top: 64px !important;
    min-width: 65px !important;
 }
 .content-hole {
    margin-top: -25px;
 }
 .logo-icon {
    position: absolute;
    top: 0;
    left: 0;
 }
 .color-main-navigation {
    background-color: rgba(208, 0, 110, 1) !important;
 }
 .color-main-navigation .v-list-item__action .theme--light.v-icon{
    color: #fff;
 }
 .hole-assign h1 {
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: #F2F2F2;
 }
</style>