import Vue from 'vue'
import VueRouter from 'vue-router'
//import Dashboard from '../layout/Dashboard.vue'
import Interno from '../layout/Interno.vue'
import AdminLayout from '../layout/AdminLayout.vue'
import store from '@/store'
//import user from '@/store/user'
//import hotel from '@/store/modules/hotel'


Vue.use(VueRouter)

  const routes = [


  {
    path: '/meu-plano',
    name: 'MeuPlanoView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Assinatura/MeuPlanoView.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'home' },
        { text: 'Meu Plano', disabled: true, to: 'meu-plano' }
      ]
    }
  },
  {
    path: '/perfil',
    name: 'PerfilView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Perfil/PerfilView.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'home' },
        { text: 'Meu Perfil', disabled: true, to: 'perfil' }
      ]
    }
  },
  {
    path: '/',
    // redirect: '/login'
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/LoginView.vue')
  },

  {
    path: '/home',
    name: 'HomeView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/HomeView.vue'),
    meta: {
      requiresAuth: true,
      layout: Interno,
    }
  },

  {
    path: '/admin',
    name: 'LoginAdmin',
    component: () => import(/* webpackChunkName: "login" */ '../views/Admin/LoginAdminView.vue')
  },
  {
    path: '/admin/home',
    name: 'HomeAdmin',
    component: () => import(/* webpackChunkName: "login" */ '../views/Admin/HomeAdminView.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: AdminLayout
    }
  },
  {
    path: '/admin/usuarios',
    name: 'UsuariosAdmin',
    component: () => import(/* webpackChunkName: "login" */ '../views/Admin/UsuariosAdminView.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: AdminLayout
    }
  },



  {
    path: '/minhas-reservas',
    name: 'MinhasReservasView',
    component: () => import(/* webpackChunkName: "login" */ '../views/Reserva/MinhasReservasView.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'home' },
        { text: 'Reservas', disabled: false, to: 'minhas-reservas' }
      ]
    }
  },

  {
    path: '/processamento-reserva',
    name: 'ResultadoReservaView',
    component: () => import(/* webpackChunkName: "login" */ '../views/Reserva/ResultadoReservaView.vue'),
    props: true,
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'home' },
        { text: 'Reservas', disabled: false, to: 'minhas-reservas' }
      ]
    }
  },
  {
    path: '/cadastro',
    name: 'Register',
    component: () => import(/* webpackChunkName: "login" */ '../views/Register/RegisterView.vue')
  },
  {
    path: '/recuperar-senha',
    name: 'RecoveryPassView',
    component: () => import(/* webpackChunkName: "login" */ '../views/RecoveryPass/RecoveryPass.vue')
  },

  {
    path: '/busca/hoteis/cidade/:cidade/checkin/:checkin/checkout/:checkout/adultos/:adultos/criancas/:criancas',
    name: 'HoteisView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Hoteis/HoteisView.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: '/home' },
        { text: 'Hotéis', disabled: true, to: '/hoteis' }
      ]
    }
  },
  // {
  //   path: '/busca/hoteis/',
  //   name: 'HoteisView',
  //   component: () => import(/* webpackChunkName: "dashboard" */ '../views/Hoteis/HoteisView.vue'),
  //   meta: {
  //     //auth: true,
  //     auth: false,
  //     layout: Dashboard,
  //     breadcrumb: [
  //       { text: 'Home', disabled: true, to: 'hoteis' },
  //       { text: 'Hotéis', disabled: false, to: 'hoteis' }
  //     ]
  //   }
  // },


  {
    path: '/hoteis/:cidade/:hotelCode/:checkin/:checkout/:adultos/:criancas/:echoToken/:hotelSignature',
    name: 'QuartosView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Hoteis/QuartosView.vue'),
    props: true,
    // props: { id: 1 },
    // props: {
    //   name: 'id'
    // },
    meta: {
      requiresAuth: true,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'hoteis' },
        { text: 'Hotéis', disabled: false, to: '/hoteis' },
        { text: 'Quartos', disabled: true, to: 'hoteis/:id/quartos' }
      ]
    },
    // beforeEnter: (to, from, next) => {
    // beforeEnter: (to, NULL, next) => {
    //   // ...
    //   //console.log("BEFORE ENTER - TO",to)
    //   //console.log("BEFORE ENTER - FROM",from)
    //   if(store.hoteis == null)
    //     store.dispatch('buscaHoteis')

    //   next()
    // }
  },
  {
    path: '/reserva/:cidade/:hotelCode/:checkin/:checkout/:adultos/:criancas/:echoToken/:hotelSignature/quarto/:quarto',
    name: 'ReservaView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Reserva/ReservaView.vue'),
    props: true,
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Hotéis', disabled: false, to: '/hoteis' },
        { text: 'Hotéis', disabled: false, to: '/hoteis' },
        { text: 'Reserva', disabled: true, to: 'hoteis/:id/quartos' }
      ]
    },
    // beforeEnter: (to, from, next) => {
    //   //   // ...
    //   //   //console.log("BEFORE ENTER - TO",to)
    //   //   //console.log("BEFORE ENTER - FROM",from)
    //   //if (hotel.hoteis == null) next({ name: 'HomeView' })
    //   if (hotel.hoteis == null) next({ path: '/' })
    //   else next()
    //     // if(store.hoteis == null)
    //     //   store.dispatch('buscaHoteis')

    //   //   next()
    // }
  },
  // {
  //   path: '/minhas-reservas',
  //   name: 'Reserves',
  //   component: () => import(/* webpackChunkName: "dashboard" */ '../views/Reserves/Reserves.vue'),
  //   meta: {
  //     //auth: true,
  //     auth: false,
  //     layout: Dashboard,
  //     // breadcrumb: [
  //     //   { text: 'Home', disabled: false, to: 'hoteis' },
  //     //   { text: 'Meu Histórico', disabled: true, to: 'meu-historico' }
  //     // ]
  //   }
  // },
  {
    path: '/assinatura',
    name: 'UserDataView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/UserData/UserData.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'hoteis' },
        { text: 'Assinatura', disabled: true, to: 'assinatura' }
      ]
    }
  },
  {
    path: '/minha-assinatura2',
    name: 'AssignView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/UserData/Assign.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'hoteis' },
        { text: 'Assinatura', disabled: false, to: 'assinatura' },
        { text: 'Minha Assinatura', disabled: true, to: 'minha-assinatura' }
      ]
    }
  },
  {
    path: '/dependentes',
    name: 'DependentesView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dependentes/DependentesView.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'hoteis' },
        // { text: 'Assinatura', disabled: false, to: 'assinatura' },
        { text: 'Usuários', disabled: true, to: 'dependentes' }
      ]
    }
  },
  {
    path: '/novo-usuario',
    name: 'NovoUsuario',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dependentes/NovoUsuario.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'hoteis' },
        // { text: 'Assinatura', disabled: false, to: 'assinatura' },
        { text: 'Usuários', disabled: true, to: 'dependentes' },
        { text: 'Novo Usuário', disabled: true, to: 'novo-usuario' }
      ]
    }
  },
  {
    path: '/minha-fatura',
    name: 'InvoiceView',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/UserData/Invoice.vue'),
    meta: {
      //auth: true,
      auth: false,
      layout: Interno,
      breadcrumb: [
        { text: 'Home', disabled: false, to: 'hoteis' },
        { text: 'Assinatura', disabled: false, to: 'assinatura' },
        { text: 'Minha Fatura', disabled: true, to: 'minha-fatura' }
      ]
    }
  },
  {
    path: '*',
    name: 'Not Found',
    //component: NotFound
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/NotFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //const loggedIn = localStorage.getItem('user')
  const loggedIn = localStorage.getItem('accessToken')
  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }

  //console.log('store user userData',store.user.userData)
  // if(!store.user.userData.token){
  //   next('/login')
  // }

  //if (to.meta.requiresAuth && !store.state.auth.authId) {
  if (to.meta.requiresAuth && !store.state.user.accessToken) {
    //return { name: 'SignIn', query: { redirectTo: to.path } }
    //next('/login')
    return { name: 'Login' }
  }
  next()
  // if (to.meta.requiresGuest && store.state.auth.authId) {
  //   return { name: 'Home' }
  // }
})

export default router
