<template>
  <div id="app">
    <component :is="this.$route.meta.layout || 'div'">
     <router-view />
    </component>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  // computed: {
  //   ...mapGetters([
  //     'isLogged'
  //   ])
  // },

  methods: {
    ...mapActions({
      fetchAccessToken: 'user/fetchAccessToken'
    }),
    // logout () {
    //   this.$store.dispatch('logout')
    // }
  },

  beforeCreate() {
    console.log('USER DATA', localStorage.getItem('userData'))
    this.$store.commit('user/initialiseStore')
  },

  created() {
    //this.fetchAccessToken()
  }

}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
