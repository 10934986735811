import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import axios from 'axios'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)


import './assets/css/fixes.css'

Vue.config.productionTip = false
window.axios = axios
//axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
axios.defaults.baseURL = 'https://api.viajehole.com.br'

// filters: {
//   pretty: function(value) {
//     return JSON.stringify(JSON.parse(value), null, 2);
//   }
// }

Vue.filter('pretty', function(value){
  return JSON.stringify(JSON.parse(value), null, 2);
})

Vue.filter('real', function(value){
  // var formatter = new Intl.NumberFormat("pt-BR", {
  //   style: 'currency',
  //   currency: "BRL",
  //   minimumFractionDigits: 2
  // })
  var formatter = new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: 2
  })
  
  return formatter.format(value)
})


new Vue({
  vuetify,
  router,
  store,
  created () {
    // const userInfo = localStorage.getItem('user')
    // if (userInfo) {
    //   const userData = JSON.parse(userInfo)
    //   this.$store.commit('setUserData', userData)
    // }

    // axios.interceptors.request.use(
    //   (config) => {
    //     if (JSON.parse(localStorage.getItem('user'))) {
    //       config.headers['Authorization'] = `Bearer ${ JSON.parse(localStorage.getItem('user')).token }`;
    //     }
    
    //     return config
    //   }, 
    
    //   (error) => {
    //     return Promise.reject(error)
    //   }
    // ),
    // axios.interceptors.response.use(
    //   response => response,
    //   error => {
    //     if (error.response.status === 401) {
    //       this.$store.dispatch('endSession')
    //     }
    //     return Promise.reject(error)
    //   }
    // )
  },
  render: h => h(App)
}).$mount('#app')