import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = 'https://api.viajehole.app'


export default {

  getUsuarioeDependentes(form, callback){
    let params = qs.stringify(form)
    console.log('params', params)

    axios
      .get(// HOMOLOG
        'https://api.viajehole.app/assinatura/getusuarioedependentesbyid/',
        { params: { titular_id: '21' } }
      )
      .then(response => {
        //console.log('RESPONSE DATA USUARIOEDEPENDENTES',response)
        callback(response.data)
      })
      .catch(e => {
        console.log('axios catch')
        this.errors.push(e)
      })
  }

}